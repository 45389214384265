(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('ProductStatusUpdateResultModalController', productStatusUpdateResultModalController);

    productStatusUpdateResultModalController.$inject = ['$scope', '$windowInstance', 'header', 'statusUpdateResult'];

    function productStatusUpdateResultModalController($scope, $windowInstance, header, statusUpdateResult) {
        $scope.header = header;
        $scope.statusUpdateResult = statusUpdateResult;
        $scope.displayUpdateResult = statusUpdateResult && statusUpdateResult.length > 0;

        $scope.ok = ok;

        function ok() {
            $windowInstance.close();
        }
    }
})();
