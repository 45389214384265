(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('locationService', locationService);

    locationService.$inject = ['$q', '$http', 'dateUtility', 'serviceUrls', 'objectPropertyNameHandlerService'];

    function locationService($q, $http, dateUtility, serviceUrls, objectPropertyNameHandlerService) {
        const locationApiUrl = `${serviceUrls.webApiBaseUrl}/location`;
        const departmentApiUrl = `${serviceUrls.webApiBaseUrl}/department`;
        const deviationApiUrl = `${serviceUrls.webApiBaseUrl}/deviation`;
        const operationLogsApiUrl = `${serviceUrls.webApiBaseUrl}/operationLogs`;
        const locationInformationBaseApiUrl = `${serviceUrls.webApiBaseUrl}/locationInformation`;
        const bargeInformationBaseApiUrl = `${serviceUrls.webApiBaseUrl}/bargeInformation`;
        const barentswatchBaseUrl = "https://www.barentswatch.no/bwapi/v1/geodata/fishhealth/locality";
        const barentsWatchWebApiUrl = `${serviceUrls.webApiBaseUrl}/arrivalreport/barentsWatch`;

        return {
            getAvailableLocationsByUserId: getAvailableLocationsByUserId,
            updateLocation: updateLocation,
            getLocationsWebApi: getLocationsWebApi,
            getContractorOwnLocations: getContractorOwnLocations,
            getLocationsByContractorCustomersOfContractor: getLocationsByContractorCustomersOfContractor,
            getLocationsThroughCustomersByContractorId: getLocationsThroughCustomersByContractorId,
            getLocationByLocationNumber: getLocationByLocationNumber,
            getLocationBaseByLocationNumber: getLocationBaseByLocationNumber,
            getDepartmentsByLocationNumber: getDepartmentsByLocationNumber,
            getDeviationLogsByLocationNumber: getDeviationLogsByLocationNumber,
            getOperationLogTasksByOperationLogIds: getOperationLogTasksByOperationLogIds,
            getLocationInformationWebApi: getLocationInformationWebApi,
            addLocationInformationWebApi: addLocationInformationWebApi,
            updateLocationInformationWebApi: updateLocationInformationWebApi,
            getLocationMTBFromBarentswatch: getLocationMTBFromBarentswatch,
            getBargeInformation: getBargeInformation,
            addBargeInformationWebApi: addBargeInformationWebApi,
            updateBargeInformationWebApi: updateBargeInformationWebApi,
            getElectricityOptionsWebApi: getElectricityOptionsWebApi,
            isDefaultLocation: isDefaultLocation,
            sharingLocationWithContractors: sharingLocationWithContractors,
            batchSharingLocationsWithContractors: batchSharingLocationsWithContractors,
            GetContractorOwnAndSharedLocations: GetContractorOwnAndSharedLocations,
            getOwnedLocationsByContractorId: getOwnedLocationsByContractorId
        };

        function getAvailableLocationsByUserId() {
            const deferred = $q.defer();

            $http.get(`${locationApiUrl}/getAvailableLocationsByUserId`)
                 .then(response => deferred.resolve(response.data), () => deferred.reject());

            return deferred.promise;
        }

        function sharingLocationWithContractors(locationNumber, contractorIds){
            const deferred = $q.defer();

            $http.post(`${locationApiUrl}/sharingLocationWithContractors?locationNumber=${locationNumber}`, contractorIds).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function GetContractorOwnAndSharedLocations(contractorId){
            const deferred = $q.defer();
            const url = `${locationApiUrl}/contractorOwnAndSharedLocations?contractorId=${contractorId}`;
            $http.get(url).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getOwnedLocationsByContractorId(contractorId, locationNumber) {
            const deferred = $q.defer();
            let url = `${locationApiUrl}/ownedLocationsByContractorId?contractorId=${contractorId}`;

            if (locationNumber) {
                url += `&locationNumber=${locationNumber}`;
            }

            $http.get(url).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }


        function batchSharingLocationsWithContractors(filter){
            const deferred = $q.defer();

            $http.post(`${locationApiUrl}/batchSharingLocationsWithContractors`, filter).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function () {
                    deferred.reject();
                }
            );
            return deferred.promise;
        }

        function getLocationsWebApi(contractorId) {
            const deferred = $q.defer(),
                requestUrl = locationApiUrl;
            $http({
                url: requestUrl,
                method: "GET",
                params: { contractorId: contractorId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function getContractorOwnLocations(contractorId) {
            const deferred = $q.defer(),
                requestUrl = locationApiUrl + '/contractorOwnLocations';
            $http({
                url: requestUrl,
                method: "GET",
                params: { contractorId: contractorId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function getLocationsByContractorCustomersOfContractor(contractorId) {
            const deferred = $q.defer(),
                requestUrl = locationApiUrl + '/byByContractorCustomersOfContractor';
            $http({
                url: requestUrl,
                method: "GET",
                params: { contractorId: contractorId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function getLocationsThroughCustomersByContractorId(contractorId) {
            const deferred = $q.defer(),
                requestUrl = locationApiUrl + '/throughCustomersByContractorId';
            $http({
                url: requestUrl,
                method: "GET",
                params: { contractorId: contractorId }
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function updateLocation(location) {
            const deferred = $q.defer();
            const url = locationApiUrl;

            $http
                .put(url, location)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLocationByLocationNumber(locationNumber) {
            const deferred = $q.defer();
            const requestUrl = `${locationApiUrl}/byLocationNumberForWebApp?locationNumber=${locationNumber}`;

            $http
                .get(requestUrl)
                .then((response) => deferred.resolve(objectPropertyNameHandlerService.setCamelCaseToPascalCase(response.data)))
                .catch((error) => deferred.reject(error));

            return deferred.promise;
        }

        function getLocationBaseByLocationNumber(locationNumber) {
            const deferred = $q.defer();

            $http.get(`${locationApiUrl}/getLocationBaseByLocationNumberInUtm?locationNumber=${locationNumber}`)
                .then(result => deferred.resolve(result.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getDepartmentsByLocationNumber(locationNumber) {
            const deferred = $q.defer();

            $http.get(`${departmentApiUrl}/byLocationNumber?locationNumber=${locationNumber}`)
            .then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getDeviationLogsByLocationNumber(locationNumber, fromDate, toDate) {
            const deferred = $q.defer();
            let url = `${deviationApiUrl}/deviationLogsByLocationNumber?locationNumber=${locationNumber}`;

            if (fromDate) {
                url += `&fromDate=${fromDate}`;
            }

            if (toDate) {
                url += `&toDate=${toDate}`;
            }

            $http.get(url)
            .then(function (response) {
                deferred.resolve(response.data);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function getOperationLogTasksByOperationLogIds(operationLogIds) {
            const deferred = $q.defer();

            if (!operationLogIds || operationLogIds.length === 0) {
                deferred.resolve([]);
            } else {
                $http.get(`${operationLogsApiUrl}/tasksByOperationLogIds?ids=${operationLogIds}`)
                .then(function (response) {
                    deferred.resolve(response.data);
                }, function () {
                    deferred.reject();
                });
            }

            return deferred.promise;
        }

        function getLocationInformationWebApi(locationNumber) {
            const deferred = $q.defer();
            const url = `${locationInformationBaseApiUrl}?locationNumber=${locationNumber}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addLocationInformationWebApi(locationInformation) {
            const deferred = $q.defer();
            const requestUrl = locationInformationBaseApiUrl;
            $http
                .post(requestUrl, locationInformation)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateLocationInformationWebApi(locationInformation) {
            const deferred = $q.defer();
            const requestUrl = locationInformationBaseApiUrl;
            $http
                .put(requestUrl, locationInformation)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getLocationMTBFromBarentswatch(locationNumber) {
            const deferred = $q.defer();
            const url = `${barentswatchBaseUrl}/${locationNumber}/${dateUtility.getYear()}/${dateUtility.getWeekNumber()}`;

            getAuthorizationToken().then(function(token) {
                if(token){
                    $http
                    .get(url, { headers: { 'Authorization' : token}})
                    .then(response => {
                        if (response && response.data && response.data.aquaCultureRegister
                            && response.data.aquaCultureRegister.capacity) {
                            deferred.resolve(response.data.aquaCultureRegister.capacity)
                        } else {
                            deferred.resolve(null)
                        }
                    })
                    .catch(error => deferred.reject(error));
                } else{
                    deferred.resolve(null);
                }
            });
            return deferred.promise;
        }

        function getBargeInformation(locationNumber) {
            const deferred = $q.defer();
            const url = `${bargeInformationBaseApiUrl}?locationNumber=${locationNumber}`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addBargeInformationWebApi(bargeInformation) {
            const deferred = $q.defer();
            const requestUrl = `${bargeInformationBaseApiUrl}`;
            $http
                .post(requestUrl, bargeInformation)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateBargeInformationWebApi(bargeInformation) {
            const deferred = $q.defer();
            const requestUrl = `${bargeInformationBaseApiUrl}`;
            $http
                .put(requestUrl, bargeInformation)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getElectricityOptionsWebApi() {
            const deferred = $q.defer();
            const url = `${locationInformationBaseApiUrl}/getElectricityOptions`;

            $http
                .get(url)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getAuthorizationToken() {
            const deferred = $q.defer();

            $http.get(barentsWatchWebApiUrl)
            .then(response => deferred.resolve(response.data))
            .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function isDefaultLocation(locationNumber){
            return locationNumber >= 90000000000 && locationNumber < 91000000000
        }
    }
})();
