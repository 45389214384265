(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('importPriceAdjustmentsFromCsvModalController', importPriceAdjustmentsFromCsvModalController);

    importPriceAdjustmentsFromCsvModalController.$inject = [
        '$scope',
        '$rootScope',
        '$windowInstance',
        'customerService',
        '$translate'];

    function importPriceAdjustmentsFromCsvModalController(
        $scope,
        $rootScope,
        $windowInstance,
        customerService,
        $translate) {

        $scope.ok = ok;
        $scope.cancel = cancel;
        $scope.validatedInput = true;
        $scope.reasonForPriceAdjust = "";
        $scope.importFileSelector = "priceAdjustImport";
        $scope.document = null;
        $scope.displayImportResult = false;
        $scope.importResult = null;

        $scope.fileImportOptions = {
            multiple: false,
            localization: {
                select: $translate.instant('G_SELECT'),
            },
        };

        $scope.selectedFiles = [];

        $scope.$on(`${$scope.importFileSelector}ReturnSelectedFiles`, function (event, documents) {
            $scope.selectedFiles = documents;

            if (!documents || documents.length == 0) {
                logImportResult($translate.instant('ADMIN_CUSTOMERS_PRICE_ADJUST_NO_FILE_SELECTED'));

                $rootScope.$broadcast('hideBusyIndicator', 'importPricesModalStart');
            }
            else if (documents.length == 1) {
                startImport(documents[0]);
            }
            else {
                logImportResult($translate.instant('ADMIN_CUSTOMERS_PRICE_ADJUST_MORE_THAN_ONE_FILE_SELECTED'));
                $rootScope.$broadcast('hideBusyIndicator', 'importPricesModalStart');
            }
        });

        initController();
        function initController() { }

        function ok() {
            if (!$scope.validator.validate()) {
                return;
            }
            $scope.displayImportResult = true;
            $scope.importResult = null;
            logImportResult($translate.instant('ADMIN_CUSTOMERS_PRICE_ADJUST_IMPORT_STARTED'));

            $rootScope.$broadcast('showBusyIndicator', { id: 'importPricesModalStart', destination: '#import-price-adjustments-form' });

            //use broadcast to get the selected file from the file-importer-directive
            $rootScope.$broadcast(`${$scope.importFileSelector}GetSelectedFiles`);
            //result will be caught in the on (ReturnSelectedFiles) event above, which will call the startImport function
        }

        function cancel() {
            $windowInstance.close({ result: false });
        }

        function startImport(importFile) {
            customerService.importCustomerPriceChangesCsv($scope.reasonForPriceAdjust, importFile)
                .then((importResult) => {
                    //display import result
                    $scope.displayImportResult = true;
                    logImportResult(importResult);
                    logImportResult($translate.instant('ADMIN_CUSTOMERS_PRICE_ADJUST_IMPORT_COMPLETED'));
                })
                .catch(error => {
                    logImportResult($translate.instant('ADMIN_CUSTOMERS_PRICE_ADJUST_IMPORT_FAILED'));
                    const data = typeof error.data == 'object' ? JSON.stringify(error.data) : error.data;
                    logImportResult(`Problem ${error.status} : ${data}`);
                })
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'importPricesModalStart');
                });
        }

        function logImportResult(logText) {
            if ($scope.importResult) {
                $scope.importResult += '\r\n' + logText;
            }
            else {
                $scope.importResult = logText;
            }
        }
    }
})();
