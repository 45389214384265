(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('UnitsAdministrationController', UnitsAdministrationController);

    UnitsAdministrationController.$inject = [
        '$q',
        '$scope',
        '$state',
        '$rootScope',
        '$kWindow',
        '$translate',
        'kendoUtility',
        'formTypes',
        'unitService',
        'baseDataService',
        'locationService',
        'mediaService',
        'commonUtility',
        'htmlElementService',
        'sanitizeService',
        'dateUtility',
        'confirmDialogService'
    ];

    function UnitsAdministrationController(
        $q,
        $scope,
        $state,
        $rootScope,
        $kWindow,
        $translate,
        kendoUtility,
        formTypes,
        unitService,
        baseDataService,
        locationService,
        mediaService,
        commonUtility,
        htmlElementService,
        sanitizeService,
        dateUtility,
        confirmDialogService
    ) {
        let expandedRowIds = [];
        const defaultGroupFilter = [];

        var canChangeState = false;
        var langId = localStorage['NG_TRANSLATE_LANG_KEY']

        window.onbeforeunload = function () {
            if ($('#units-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
            }
        };

        $scope.archiveSelectedProducts = archiveSelectedProducts;
        $scope.unSelectAllProducts = unSelectAllProducts;

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#units-administration-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();

                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });

        $scope.unitBatchNumberSearchQuery = null;
        $scope.searchByUnitBatchNumber = () => {
            $scope.unitBatchNumberSearchQuery = $("#unitBatchNumberSearchField").val().toLowerCase();
            console.log("Searching for:", $scope.unitBatchNumberSearchQuery );
            unitsAdministrationDataSource.read();
        };

        $scope.locationDropdownData = [];
        const localStorageLocationDropdownKey = 'productRegister_locationDropdownData';

        let storedLocationDropdownData = localStorage.getItem(localStorageLocationDropdownKey);
        $scope.locationDropdownData = storedLocationDropdownData
            ? JSON.parse(storedLocationDropdownData).map(Number)
            : [];

        $("#locationDropdownTree").kendoDropDownTree({
            dataSource: new kendo.data.HierarchicalDataSource({
                transport: {
                    read: function (options) {
                        locationService.getOwnedLocationsByContractorId($rootScope.authData.contractorId)
                            .then(function (result) {
                                options.success(result);
                            })
                            .catch(function (error) {
                                console.error('Error loading locations:', error);
                                options.error(error);
                            });
                    }
                },
                schema: {
                    model: {
                        id: "locationNumber",
                        fields: {
                            locationNumber: { type: "number" },
                            name: { type: "string" }
                        }
                    },
                    parse: function (data) {
                        return data.map(function (location) {
                            return {
                                locationNumber: location.locationNumber,
                                name: `${location.name} - ${location.locationNumber}`
                            };
                        });
                    }
                },
                sort: { field: "name", dir: "asc" }
            }),
            dataValueField: "locationNumber",
            dataTextField: "name",
            filter: "contains",
            placeholder: $translate.instant('ADMIN_UNITS_LOCATION_SELECT'),
            checkboxes: true,
            size: "large",
            width: 600,
            height: 400,
            autoClose: false,
            tagMode: "single",
            checkAllTemplate: $translate.instant('G_SELECT_UNSELECT_ALL'),
            checkAll: true,
            messages: {
                singleTag: $translate.instant('ADMIN_UNITS_LOCATION_SELECTED')
            },
            value: $scope.locationDropdownData,
            change: function (e) {
                var dropDownTree = e.sender;
                var selectedIds = dropDownTree.value();

                $scope.locationDropdownData = selectedIds;
                localStorage.setItem(localStorageLocationDropdownKey, JSON.stringify(selectedIds));

                if (selectedIds.length === 0) {
                    unitsAdministrationDataSource.read();
                }
            },
            close: function(e) {
                if ($scope.locationDropdownData.length > 0) {
                    unitsAdministrationDataSource.read();
                }
            }
        });

        var unitsAdministrationDataSource = new kendo.data.DataSource({
            transport: {
                read: function (e) {
                    var activeFilter = {
                        field: "IsActive",
                        operator: "contains",
                        value: "1"
                    };
                    !e.data.filter
                        ? e.data.filter = { filters: [] }
                        : e.data.filter.filters;

                    e.data.filter.filters.push(activeFilter)

                    unitService.getUnitsByFilter(
                        e.data.page,
                        e.data.pageSize,
                        e.data.filter.filters,
                        e.data.sort,
                        $scope.locationDropdownData,
                        $scope.unitBatchNumberSearchQuery
                    ).then(response => {
                        $scope.units = response.items;
                        e.success({
                            data: response.items,
                            total: response.totalCount
                        });
                    });
                },
                update: e => onUnitsGridUpdate(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            pageSize: 500,
            schema: {
                data: "data",
                total: "total",
                model: {
                    id: 'id',
                    fields: {
                        unitGroupId: { editable: true, validation: { required: true } },
                        externalUnitId: { editable: true },
                        description: { editable: true },
                        supplierName: { editable: false },
                        measurementUnitTypeId: { editable: true },
                        resourceId: { editable: true },
                        standardPrice: { editable: true },
                        inPrice: { editable: true },
                    }
                },
            }
        });

        function isBatchValid(batch) {
            return {
                hasCertificate: !!batch.mediaId,
                hasLifetime: !!batch.lifetimeInYearsMooring,
                hasValidLifetime: batch.lifetimeInYearsMooring > 0,
                hasCertificateDate: !!batch.certificateIssuedDate,
                isComplete: !!batch.mediaId &&
                    !!batch.lifetimeInYearsMooring &&
                    batch.lifetimeInYearsMooring > 0 &&
                    !!batch.certificateIssuedDate
            };
        }

        const unitsAdministrationGridOptions = langId => ({
            dataSource: unitsAdministrationDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            pageable: {
                pageSize: 500,
                refresh: true,
                pageSizes: [100, 250, 500],
                buttonCount: 5,
                messages: {
                    display: $translate.instant('G_PAGING_DISPLAY'),
                    empty: $translate.instant('G_PAGING_EMPTY'),
                    page: $translate.instant('G_PAGING_PAGE'),
                    of: $translate.instant('G_PAGING_OF'),
                    itemsPerPage: $translate.instant('G_PAGING_ITEMSPERPAGE'),
                    first: $translate.instant('G_PAGING_FIRST'),
                    previous: $translate.instant('G_PAGING_PREVIOUS'),
                    next: $translate.instant('G_PAGING_NEXT'),
                    last: $translate.instant('G_PAGING_LAST'),
                    refresh: $translate.instant('G_PAGING_REFRESH')
                }
            },
            persistSelection: true,
            change: function (e) {
                if (this.select() && this.select().length > 0) {
                    setArchiveSelectedButtonDisabled(false);
                    setUnSelectAllButtonDisabled(false);
                } else {
                    setArchiveSelectedButtonDisabled(true);
                    setUnSelectAllButtonDisabled(true);
                }
            },
            columns: [
                {
                    field: 'id',
                    hidden: true
                },
                {
                    selectable: true,
                    width: "40px"

                },
                {
                    field: 'unitGroupId',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_GROUP'),
                    template: function(dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        let item = $scope.unitGroups && $scope.unitGroups
                            .find(unitGroup =>
                                unitGroup.id === dataItem.unitGroupId);

                        if (item) {
                            dataItem.unitGroup = item;
                            dataItem.unitGroupDescription = item.description;
                        }
                        return dataItem.unitGroupDescription ? dataItem.unitGroupDescription : '';
                    },
                    editor: unitGroupsDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_PRODUCT_GROUP') + ": #=items[0] && items[0].unitGroupDescription ? items[0].unitGroupDescription : '' #"
                },
                {
                    field: 'externalUnitId',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_NUMBER'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.externalUnitId ? sanitizeService.sanitizingStringContent(dataItem.externalUnitId) : '';
                    },
                },
                {
                    field: 'description',
                    title: $translate.instant('ADMIN_UNITS_PRODUCT_NAME'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.description ? sanitizeService.sanitizingStringContent(dataItem.description) : '';
                    },
                },
                {
                    field: 'supplierName',
                    title: $translate.instant('ADMIN_UNITS_SUPPLIER'),
                    template: function(dataItem) {
                        const hasSupplier = dataItem.supplier !== null;
                        dataItem.supplierName = hasSupplier ? dataItem.supplier.name : '';
                        const warningIcon = !hasSupplier ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_SUPPLIER') + '"></i> ' : '';
                        return warningIcon + dataItem.supplierName;
                    },
                },
                {
                    field: 'measurementUnitTypeId',
                    title: $translate.instant('ADMIN_UNITS_MEASUREMENT_UNIT'),
                    template: function(dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }

                        let item = $scope.measurementTypes.find(function(measurementType) {
                            return measurementType.measurementUnitTypeId === dataItem.measurementUnitTypeId;
                        });

                        if (item) {
                            dataItem.measurementUnitType = item;
                            dataItem.measurementUnitTypeName = item.measurementUnitDescription;
                        }
                        return dataItem.measurementUnitTypeName ? dataItem.measurementUnitTypeName : '';
                    },
                    editor: measurementUnitTypeDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_MEASUREMENT_UNIT', null, null, langId) + ": #=items[0] && items[0].measurementUnitTypeName ? items[0].measurementUnitTypeName : '' #"
                },
                {
                    field: 'resourceId',
                    title: $translate.instant('ADMIN_UNITS_RESOURCE'),
                    template: function(dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        if (dataItem.resourceId) {
                            let item = $scope.resources.find(function(resource) {
                                return resource.resourceId == dataItem.resourceId;
                            });

                            if (item) {
                                dataItem.resource = item;
                                dataItem.resourceName = item.resourceDescription;
                            }
                        }

                        return dataItem.resourceName ? dataItem.resourceName : '';
                    },
                    editor: resourceDropDownEditor,
                    groupHeaderTemplate: $translate.instant('ADMIN_UNITS_RESOURCE') + ": #=items[0] && items[0].resourceName ? items[0].resourceName : '' #",
                },
                {
                    field: 'standardPrice',
                    title: $translate.instant('ADMIN_UNITS_STANDARD_PRICE'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.standardPrice ? sanitizeService.sanitizingStringContent(dataItem.standardPrice) : '';
                    },
                },
                {
                    field: 'inPrice',
                    title: $translate.instant('ADMIN_UNITS_IN_PRICE'),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.inPrice ? sanitizeService.sanitizingStringContent(dataItem.inPrice) : '';
                    },
                },
                {
                    command: [
                        {
                            name: 'editUnit',
                            click: e => editUnit(e),
                            template: `<a class="k-grid-editUnit"><i class="fas fa-edit" title="${$translate.instant('G_EDIT') }"></i></a>`
                        },
                        {
                            name: 'archiveUnit',
                            template: `<a class="k-grid-archiveUnit"><i class="fa fa-archive" title="${$translate.instant('ADMIN_UNITS_ARCHIVE_BUTTON_TEXT') }"></i></a>`,
                            click: e => setUnitArchived(e),
                        },
                    ],
                    width: 100,
                }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                    sanitizeService.sanitizingGridContent(e);
                });
            },
            filterable: {
                name: "FilterMenu",
                extra: false,
                operators: {
                    string: {
                        contains: $translate.instant('G_SEARCH_OPTION_CONTAINS', null, null, langId),
                    }
                },
            },
            filterMenuInit: function (e) {
                if (e.field === "resourceName" || e.field === "supplierName") {
                    let filterMultiCheck = this.thead.find("[data-field=" + e.field + "]").data("kendoFilterMenu");
                    filterMultiCheck.container.empty();
                    filterMultiCheck.checkSource.sort({
                        field: e.field,
                        dir: "asc"
                    });
                    filterMultiCheck.checkSource.filter([]);
                    filterMultiCheck.checkSource.data(filterMultiCheck.checkSource.view().toJSON());
                    filterMultiCheck.createCheckBoxes();
                }
            },
            resizable: true,
            sortable: true,
            groupable: true,
            detailInit: detailInit,
            detailCollapse: (e) => {
                const dataGrid = $('#units-administration-grid').data('kendoGrid');
                const dataItems = dataGrid.dataSource.view();

                const dataItem = dataItems.find(x => x.uid === e.masterRow.data('uid'));

                if (dataItem) {
                    expandedRowIds = expandedRowIds.filter(x => x !== dataItem.id);
                }
            },
            detailTemplate: kendo.template($('#unit-batch-template').html()),
            dataBound: function () {
                const dataGrid = $('#units-administration-grid').data('kendoGrid');
                const dataItems = dataGrid.dataSource.view();

                // reopen expanded rows on grid refresh
                $.each(expandedRowIds, function (_, v) {
                    $.each(dataItems, function (_, y) {
                        if (v === y.id) {
                            dataGrid.expandRow(dataGrid.tbody.find("tr[data-uid='" + y.uid + "']"));
                        }
                    });
                });

                //hide/disable select all button in products grid
                var selectAllCheckBox = $('#units-administration-grid th input[aria-label="Select all rows"]');
                selectAllCheckBox[0].disabled = true;
            }
        });

        var unitBatchGridOptions = data => ({
            dataSource: {
                data,
                schema: {
                    model: {
                        id: 'id',
                    },
                    parse: function (data) {
                        // If we have an active batch search query, filter out batches where query string is not contained in batch name
                        if ($scope.unitBatchNumberSearchQuery && $scope.unitBatchNumberSearchQuery !== "") {
                            return data.filter((dataItem) => {
                                return dataItem.batch.toLowerCase().includes($scope.unitBatchNumberSearchQuery.toLowerCase());
                            });
                        }
                        
                        return data;
                    }
                }
            },
            noRecords: true,
            messages: {
                noRecords: $translate.instant('G_NORESULTS')
            },
            persistSelection: true,
            change: function (e) {
                var id = this.element[0].id.split("_")[0];
                if (id && this.select() && this.select().length > 0) {
                    document.getElementById(`${id}_move`).style.visibility = 'visible';
                    document.getElementById(`${id}_archive`).style.visibility = 'visible';
                } else {
                    document.getElementById(`${id}_move`).style.visibility = 'hidden';
                    document.getElementById(`${id}_archive`).style.visibility = 'hidden';
                }
            },
            columns: [{
                    hidden: true,
                    field: 'id'
                },
                {
                    field: 'batch',
                    title: $translate.instant('ADMIN_UNITS_BATCH_NUMBER'),
                    width: "350px"
                },
                {
                    field: 'mediaId',
                    title: $translate.instant('ADMIN_UNITS_CERTIFICATE'),
                    width: "350px",
                    template: function (dataItem) {
                        const warningIcon = !dataItem.mediaId ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_CERTIFICATE') + '"></i> ' : '';

                        const text = dataItem.mediaId ?
                            $translate.instant('ADMIN_UNITS_VIEW_CERTIFICATE', null, null, langId) :
                            $translate.instant('ADMIN_UNITS_UPLOAD_CERTIFICATE', null, null, langId);

                        return warningIcon + '<button class="k-button btn btn-default k-grid-certificate" data-id="' +
                            dataItem.id + '" data-mediaid="' + dataItem.mediaId + '">' + text + '</button>';
                    },
                },
                {
                    field: 'certificateIssuedDate',
                    title: $translate.instant('UNIT_BATCH_CERTIFICATE_ISSUED_DATE'),
                    width: "150px",
                    template: function (dataItem) {
                        const warningIcon = !dataItem.certificateIssuedDate ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_MISSING_CERTIFICATE_DATE') + '"></i> ' : '';
                        return warningIcon + (dataItem.certificateIssuedDate ? dateUtility.formatDate(dataItem.certificateIssuedDate) : '');
                    },
                },
                {
                    field: 'isActive',
                    title: $translate.instant('ADMIN_UNITS_ACTIVE'),
                    width: "60px",
                    template: function (dataItem) {
                        const checked = dataItem.isActive ? 'checked' : '';
                        return `<div class="center-content"><input type="checkbox" class="is-active-checkbox" ${checked} disabled/></div>`;
                    },
                },
                {
                    field: 'lifetimeInYearsMooring',
                    title: $translate.instant('ADMIN_UNITS_LIFETIME_IN_YEARS'),
                    width: "350px",
                    template: function (dataItem) {
                        const validity = isBatchValid(dataItem);
                        const warningIcon = (!validity.hasLifetime || !validity.hasValidLifetime) ?
                            '<i class="fas fa-exclamation-triangle text-warning" title="' +
                            $translate.instant('ADMIN_UNITS_INVALID_LIFETIME') + '"></i> ' : '';
                        return warningIcon + (dataItem.lifetimeInYearsMooring || '');
                    },
                },
                {
                    field: 'locationLogSummaries',
                    title: $translate.instant('ADMIN_UNITS_LOCATIONS'),
                    width: "150px",
                    template: function (dataItem) {
                        if (!dataItem.locationLogSummaries) return '0';

                        const uniqueLocations = new Set(
                            dataItem.locationLogSummaries.map(log => log.locationName)
                        );

                        return `<a class="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base location-details-btn">${uniqueLocations.size}</a>`;
                    },
                    attributes: {
                        "class": "text-center"
                    }
                },
                {
                    selectable: true,
                    width: "40px"
                },
                {
                    command: [{
                            name: 'editUnitBatch',
                            click: e => {
                                e.preventDefault();
                                var data = getCurrentBatchRowData(e);
                                editUnitBatch(data.unitId, data.id);
                            },
                        template: `<a class="k-grid-editUnitBatch"><i class="fas fa-edit" title="${$translate.instant('G_EDIT')}"></i></a>`
                        },
                        {
                            name: 'restoreUnitBatch',
                            template: `<a class="k-grid-restoreUnitBatch"><i class="fa fa-undo" title="${$translate.instant('ADMIN_UNITS_RESTORE_BUTTON_TEXT')}"></i></a>`,
                            visible: function (dataItem) {
                                return !dataItem.isActive
                            },
                            click: e => {
                                e.preventDefault();
                                var data = getCurrentBatchRowData(e);
                                restoreUnitBatch(data);
                            }
                        }],
                    width: 80
                }
            ],
            dataBound: function (e) {
                var grid = e.sender;
                var rows = grid.tbody.find("tr");

                rows.each(function (index, row) {
                    var dataItem = grid.dataItem(row);
                    var validity = isBatchValid(dataItem);

                    if (!validity.isComplete) {
                        $(row).addClass('incomplete-row');
                    }
                });
            },
        });

        $scope.unitsAdministrationGridOptions = unitsAdministrationGridOptions($translate.use());
        $scope.unitBatchGridOptions = unitBatchGridOptions([]);
        $scope.setUnitArchived = setUnitArchived;
        $scope.units = [];
        $scope.addNewUnit = addNewUnit;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;
        $scope.editUnit = editUnit;
        $scope.measurementTypes = [];
        $scope.resources = [];

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.unitsAdministrationGridOptions = unitsAdministrationGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.unitsAdministrationGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#units-administration-grid')]);
            langId = code;
        });

        function unitGroupsDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_UNITS_SELECT_PRODUCT_GROUP'),
                    dataTextField: 'description',
                    dataValueField: 'id',
                    dataSource: {
                        data: $scope.unitGroups
                    }
                });
        }

        function measurementUnitTypeDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_UNITS_SELECT_MEASUREMENT_UNIT'),
                    dataTextField: 'measurementUnitDescription',
                    dataValueField: 'measurementUnitTypeId',
                    dataSource: {
                        data: $scope.measurementTypes
                    }
                });
        }

        function resourceDropDownEditor(container, options) {
            $('<input required name="' + options.field + '"/>')
                .appendTo(container)
                .kendoDropDownList({
                    autoBind: true,
                    optionLabel: $translate.instant('ADMIN_UNITS_SELECT_RESOURCE'),
                    dataTextField: 'resourceDescription',
                    dataValueField: 'resourceId',
                    dataSource: {
                        data: $scope.resources
                    }
                });
        }

        initController();

        function initController() {

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });

            htmlElementService.checkIfElementExists("filter-group")
                .then(function (result) {
                    commonUtility.calculateAdminTabMenuWidth();
                });

            toggleChangesButtons(true)
            setArchiveSelectedButtonDisabled(true);
            setUnSelectAllButtonDisabled(true);

            $q.all({
                getUnitGroupsByContractor:      unitService.getUnitGroupsByContractor($rootScope.authData.contractorId),
                getMeasurementUnitTypesWebapi:  baseDataService.getMeasurementUnitTypesWebapi(),
                getResourcesWebApi:             baseDataService.getResourcesWebApi()
            })
            .then(data => {
                $scope.unitGroups       = data.getUnitGroupsByContractor;
                $scope.measurementTypes = data.getMeasurementUnitTypesWebapi;
                $scope.resources        = data.getResourcesWebApi;
                refreshProductsGrid();
            })
            .catch(error => console.error(error))
            .finally(() => {
                $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
            });
        }


        function openUnitWindow(currentFormType, unit) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: currentFormType === formTypes.add ? $translate.instant('ADMIN_CUSTOMERS_ADD_UNIT') : $translate.instant('ADMIN_UNITS_EDIT'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/units/units-details-modal-administration-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'unitsDetailsModalAdministrationController',
                resolve: {
                    currentFormType: () => currentFormType,
                    unit: () => unit,
                    unitGroups: () => $scope.unitGroups,
                    measurementTypes: () => $scope.measurementTypes,
                    resources: () => $scope.resources,
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    if (currentFormType == formTypes.add) {
                        $scope.units = $scope.units.concat([result]);
                    } else {
                        $scope.units = $scope.units.map(u => (u.id === result.id ? result : u));
                    }
                    refreshProductsGrid();
                }
            });
        }

        function addNewUnit() {
            openUnitWindow(formTypes.add, null)
        }

        function editUnit(event) {
            var unit = getUnitFromGrid(event)
            openUnitWindow(formTypes.edit, unit)
        }

        function setUnitArchived(event) {
            var unit = getUnitFromGrid(event)
            unitService.getUnitBatches(unit.id)
                .then(batches => {
                    var activeBatches = batches.filter(x => x.isActive === true);
                    if (activeBatches && activeBatches.length > 0) {
                        openInformationDialog(`${unit.description} ${$translate.instant('ADMIN_UNITS_ARCHIVE_BATCHES_EXIST_WARNING')}`);
                    }
                    else {
                        confirmDialogService.open(
                            {
                                modalWidth: 400,
                                modalHeight: 150
                            },
                            $translate.instant("ADMIN_UNITS_ARCHIVE_WARNING") + `${"\n"}${unit.externalUnitId} ${unit.description}`,
                            $translate.instant("ADMIN_UNITS_ARCHIVE_WARNING_TITLE"),
                            $translate.instant("G_OK"),
                            $translate.instant("G_CANCEL")
                        ).then(dialogResult => {
                            if (dialogResult === true) {
                                unitService.updateUnitActiveStatus([unit.id], false).then(result => {
                                    openInformationDialog($translate.instant("ADMIN_UNITS_ARCHIVED_MESSAGE"));
                                    refreshProductsGrid();
                                    $scope.$broadcast('refreshProductArchiveGrid');
                                });
                            }
                        });
                    }
                });
        }

        $rootScope.$on('refreshProductsGrid', () => refreshProductsGrid());

        function refreshProductsGrid() {
            const grid = $('#units-administration-grid').data('kendoGrid');
            grid.clearSelection();

            if ($scope.unitsAdministrationGridOptions) {
                $scope.unitsAdministrationGridOptions.dataSource.read();
            }
        }

        function openInformationDialog(dialogContent) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 400
                },
                templateUrl: 'app/shared/popups/information-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'InformationModalController',
                resolve: {
                    content: () => { return dialogContent }
                }
            });
            windowInstance.result.then(function (response) {
                return response;
            });
        }

        function getUnitFromGrid(event) {
            const sender = event.currentTarget;
            const row = angular.element(sender).closest('tr');
            return $('#units-administration-grid')
                .data('kendoGrid')
                .dataItem(row)
                .toJSON();
        }

        function detailInit(e) {
            const unitId = e.data.id;
            if (!expandedRowIds.includes(unitId)) {
                expandedRowIds.push(unitId);
            }

            let requests = {};
            requests.getUnitBatches = unitService.getUnitBatchesWithLocationLogSummaries(unitId);
            $q.all(requests)
                .then(data => {
                    const element = e.detailRow.find(`#${unitId}_grid`);
                    $scope.unitBatchGridOptions = unitBatchGridOptions(data.getUnitBatches.filter(batch => batch.isActive === true));
                    element.kendoGrid($scope.unitBatchGridOptions);

                    // Add unitsBatch
                    $(`#${unitId}_add`)
                        .attr('title', $translate.instant('G_NEWITEM'))
                        .click(() => addUnitBatch(unitId));

                    // Show or hide archived batches
                    $(`#${unitId}_show-archived-switch-label`)
                        .attr('title', $translate.instant('ADMIN_UNITS_SHOW_ARCHIVED'))
                        .click(() => getFilteredBatches(unitId))

                    // Move unitsBatch
                    $(`#${unitId}_move`)
                        .attr('title', $translate.instant('ADMIN_UNITS_MOVE_UNIT_BATCHES'))
                        .click(() => moveUnitBatches(unitId));
                    document.getElementById(`${unitId}_move`).style.visibility = 'hidden';

                    // Archive unitsBatch
                    $(`#${unitId}_archive`)
                        .attr('title', $translate.instant('ADMIN_UNITS_ARCHIVE_UNIT_BATCHES'))
                        .click(() => archiveUnitBatches(unitId));
                    document.getElementById(`${unitId}_archive`).style.visibility = 'hidden';

                    // Certificate open
                    $('.k-grid-certificate').click(function (e) {
                        if ($(e.target).data("mediaid")) {
                            viewCertificate($(e.target).data("id"), $(e.target).data("mediaid"))
                        } else {
                            uploadCertificate($(e.target).data("id"))
                        }
                    })
                })
                .catch(error => console.error(error))
        }


        function getFilteredBatches(unitId) {
            let requests = {};
            requests.getUnitBatches = unitService.getUnitBatchesWithLocationLogSummaries(unitId);
            $q.all(requests)
                .then(data => {
                    //find state of show archived switch for the current unitbatches
                    let showArchivedBatches = document.getElementById(`${unitId}_show-archived-switch`).checked;

                    let batches = !showArchivedBatches ? data.getUnitBatches.filter(x => x.isActive === true) : data.getUnitBatches;

                    const grid = $(`#${unitId}_grid`).data('kendoGrid');
                    grid.clearSelection();
                    grid.dataSource.data(batches); 
                })
                .catch(error => console.error(error));
        }

        function openUnitBatch(currentFormType, locations, unitId, unitBatch) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: currentFormType === formTypes.edit ? $translate.instant('ADMIN_UNITS_EDIT_UNIT_BATCH') : $translate.instant('ADMIN_UNITS_ADD_UNIT_BATCH'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/units/units-batch-details-modal-administration-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'unitsBatchDetailsModalAdministrationController',
                resolve: {
                    currentFormType: () => currentFormType,
                    locations: () => locations,
                    unitId: () => unitId,
                    unitBatch: () => unitBatch
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    refreshProductsGrid();
                }
            });
        }

        function addUnitBatch(unitId) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });
            let requests = {};
            requests.getLocationsWebApi = locationService.getLocationsWebApi($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    var locations = data.getLocationsWebApi;
                    openUnitBatch(formTypes.add, locations, unitId, null)
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });
        }

        function moveUnitBatches(unitId) {
            var selectedRowItems = getSelectedUnitBatches(unitId);
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('ADMIN_UNITS_MOVE_UNIT_BATCHES'),
                    resizable: true,
                    width: 520,
                    visible: false
                },
                templateUrl: 'app/units/move-units-batch-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'moveUnitsBatchModalController',
                resolve: {
                    units: () => $scope.units,
                    unitId: () => unitId,
                    selectedUnitsbatches: () => selectedRowItems
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    initController();
                }
            });
        }

        function archiveUnitBatches(unitId) {
            var selectedRowItems = getSelectedUnitBatches(unitId);

            let unitBatchIds = [];
            let batchWarnings = [];
            selectedRowItems.map(batch => {
                var uniqueLocations = batch.locationLogSummaries ? batch.locationLogSummaries.map(log => log.locationName) : [];
                if (uniqueLocations.length > 0 && batch.isActive) {
                    //add to warnings
                    batchWarnings.push(
                        batch.batch
                    );
                }
                else {
                    unitBatchIds.push(batch.id);
                }
            });

            if (unitBatchIds.length > 0) {
                unitService.setActiveStatusOnUnitBatches(unitId, unitBatchIds, false)
                    .then(result => {
                        let messageContent = `${unitBatchIds.length.toString()} ${$translate.instant("ADMIN_UNITS_BATCHES_ARCHIVED_INFORMATION")}`;
                        if (batchWarnings.length > 0) {
                            messageContent = `${messageContent}. ${(batchWarnings.length > 1 ? $translate.instant("ADMIN_UNITS_BATCHES_WARNING_PL") : $translate.instant("ADMIN_UNITS_BATCHES_WARNING_S"))} ${batchWarnings.join(", ")}`;
                        }

                        getFilteredBatches(unitId);
                        //this has to be run inside here, because of promises
                        openInformationDialog(messageContent);
                    });
            }
            else {
                //have to open this dialog in two places, because if no batches to archive, the one above will not be run.
                openInformationDialog(`${(batchWarnings.length > 1 ? $translate.instant("ADMIN_UNITS_BATCHES_WARNING_PL") : $translate.instant("ADMIN_UNITS_BATCHES_WARNING_S"))} ${batchWarnings.join(", ")}`);
            }
        }

        function getSelectedUnitBatches(id) {
            var grid = $(`#${id}_grid`).data('kendoGrid');
            var rows = grid.select();
            var selectedRowItems = [];
            rows.each(function (index, row) {
                selectedRowItems.push(grid.dataItem(row));
            });
            return selectedRowItems;
        }

        function editUnitBatch(unitId, batchId) {
            let unitBatch = getCurrentUnitBatchRowData(unitId, batchId);

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });
            let requests = {};
            requests.getLocationsWebApi = locationService.getLocationsWebApi($rootScope.authData.contractorId);
            $q.all(requests)
                .then(data => {
                    var locations = data.getLocationsWebApi;
                    openUnitBatch(formTypes.edit, locations, unitId, unitBatch);
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });
        }

        function getCurrentUnitBatchRowData(unitId, batchId) {
            var batchGrid = $(`#${unitId}_grid`).data('kendoGrid');
            let currentBatch = batchGrid.dataSource.data().find(batch => {
                return batch.id === batchId;
            });

            return currentBatch;
        }

        function viewCertificate(batchId, mediaId) {
            var mediaUrl = mediaService.createMediaUrl(mediaId)
            window.open(mediaUrl, "_blank");
        }

        function uploadCertificate(batchId) {
            var input = document.createElement('input');
            input.type = 'file';

            input.onchange = e => {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitsAdministrationGrid',
                    destination: '#units-administration-grid'
                });

                var file = e.target.files[0];
                $scope.$applyAsync(function () {
                    mediaService.asyncEncodeImageFile(file)
                        .then((base64FileContent) => {
                            mediaService.uploadFileWebApi(file.name, base64FileContent).then(function (uploadResult) {
                                if (uploadResult) {
                                    let requests = {};
                                    requests.uploadCertificate = unitService.uploadCertificate(batchId, uploadResult);
                                    $q.all(requests)
                                        .then(data => {
                                            refreshProductsGrid();
                                            $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                                        })
                                        .catch(error => console.error(error))
                                } else {
                                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                                }
                            });
                        });
                });
            }
            input.click();
        }

        function saveChanges() {
            $scope.unitsAdministrationGridOptions.dataSource.sync();
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            refreshProductsGrid();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-units');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-units');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function onUnitsGridUpdate(e) {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'unitsAdministrationGrid',
                destination: '#units-administration-grid'
            });
            unitService.updateUnit(e.data)
                .then(function (response) {
                    if (response) {
                        toggleChangesButtons(true);
                        e.success();
                        $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                    } else {
                        e.error();
                        $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                    }
                }, function (error) {
                    e.error(error);
                    $rootScope.$broadcast('hideBusyIndicator', 'unitsAdministrationGrid');
                });
        }

        $(document).on('click', '.location-details-btn', function(e) {
            e.stopPropagation();
            // Find the closest grid to this button
            const gridElement = $(e.target).closest('.k-grid');
            const grid = gridElement.data('kendoGrid');
            const row = $(e.target).closest('tr');
            const dataItem = grid.dataItem(row);
            $scope.openUnitBatchLocationDetailsWindow(dataItem);
            $scope.$apply();
        });

        $scope.openUnitBatchLocationDetailsWindow = function(unitBatch) {
            $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    width: 800,
                    visible: false
                },
                templateUrl: 'app/units/unit-batch-location-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'unitBatchLocationDetailsModalController',
                resolve: {
                    unitBatch: () => unitBatch
                }
            });
        }

        function getCurrentBatchRowData(e) {
            var sender = e.currentTarget;
            var row = angular.element(sender).closest("tr");
            var data = $(`#${e.delegateTarget.id}`).data("kendoGrid").dataItem(row).toJSON();

            return data;
        }

        function restoreUnitBatch(data) {
            let unitBatchIds = [];
            unitBatchIds.push(data.id);

            unitService.setActiveStatusOnUnitBatches(data.unitId, unitBatchIds, true)
                .then(result => {
                    getFilteredBatches(data.unitId);
                });
        }

        function archiveSelectedProducts() {
            var grid = $("#units-administration-grid").data("kendoGrid");
            var selectedRows = grid.select();
            let selectedProductIds = [];
            let selectedProducts = [];

            $(selectedRows).each(function () {
                var rowdata = grid.dataItems().find(r => r.uid == $(this).attr("data-uid"));
                selectedProducts.push(rowdata);
            });

            selectedProductIds = selectedProducts.map(r => r.id);
            
            if (selectedProductIds.length > 0) {
                confirmDialogService.open(
                    {
                        modalWidth: 400,
                        modalHeight: 150
                    },
                    $translate.instant("ADMIN_UNITS_ARCHIVE_WARNING_PL"),
                    $translate.instant("ADMIN_UNITS_ARCHIVE_WARNING_TITLE"),
                    $translate.instant("G_OK"),
                    $translate.instant("G_CANCEL")
                ).then(dialogResult => {
                    if (dialogResult === true) {
                        unitService.updateUnitActiveStatus(selectedProductIds, false).then(result => {
                            var resultMessages = buildResultMessages(selectedProducts, result);
                            var header = `${result.updatedProducts.length} ${$translate.instant("ADMIN_UNITS_ARCHIVED_MESSAGE_PL")}`
                            openStatusUpdateResultDialog(header, resultMessages.join('\n'));
                            refreshProductsGrid();
                            $scope.$broadcast('refreshProductArchiveGrid');
                        });
                    }
                });
            }
        }

        function setArchiveSelectedButtonDisabled(disabled) {
            var archiveSelectedButton = $('button.k-button.k-grid-archive-selected-products');
            if (disabled) {
                archiveSelectedButton.prop('disabled', true);
                archiveSelectedButton.addClass('k-state-disabled');
                archiveSelectedButton.hide();
            } else {
                archiveSelectedButton.prop('disabled', false);
                archiveSelectedButton.removeClass('k-state-disabled');
                archiveSelectedButton.show();
            }
        }

        function unSelectAllProducts() {
            var grid = $("#units-administration-grid").data("kendoGrid");
            grid.clearSelection();
        }

        function setUnSelectAllButtonDisabled(disabled) {
            var unSelectAllProductsButton = $('button.k-button.k-grid-unselect-all-products');
            if (disabled) {
                unSelectAllProductsButton.prop('disabled', true);
                unSelectAllProductsButton.addClass('k-state-disabled');
                unSelectAllProductsButton.hide();
            } else {
                unSelectAllProductsButton.prop('disabled', false);
                unSelectAllProductsButton.removeClass('k-state-disabled');
                unSelectAllProductsButton.show();
            }
        }

        function openStatusUpdateResultDialog(dialogHeader, statusUpdateResult) {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    resizable: false,
                    visible: false,
                    width: 550
                },
                templateUrl: 'app/shared/popups/product-status-update-result-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'ProductStatusUpdateResultModalController',
                resolve: {
                    header: () => { return dialogHeader },
                    statusUpdateResult: () => { return statusUpdateResult }
                }
            });
            windowInstance.result.then(function (response) {
                return response;
            });
        }

        function buildResultMessages(selectedProducts, updateResult) {
            var messages = [];

            if (updateResult.rejectedProductIds && updateResult.rejectedProductIds.length > 0) {
                messages.push($translate.instant("ADMIN_UNITS_ARCHIVE_BATCHES_EXIST_WARNING_PL"));
                messages.push("");
                updateResult.rejectedProductIds.map(unitId => {
                    var product = selectedProducts.find(p => p.id === unitId);
                    messages.push(product.description);
                });
            }

            return messages;  
        }
    }
})();
