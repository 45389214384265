(function () {
    'use strict';

    angular.module('NaviaqWebApp').factory('unitService', unitService);

    unitService.$inject = ['$q', '$http', 'serviceUrls'];

    function unitService($q, $http, serviceUrls) {
        const unitsWebApiUrl = `${serviceUrls.webApiBaseUrl}/unit`;
        const unitGroupsWebApiUrl = `${serviceUrls.webApiBaseUrl}/unitGroups`;
        const unitBatchWebApiUrl = `${serviceUrls.webApiBaseUrl}/unitBatch`;

        return {
            getUnits: getUnits,
            getUnitsByContractorId: getUnitsByContractorId,
            getUnitsAsDropdownByContractorId: getUnitsAsDropdownByContractorId,
            getUnitsByFilter: getUnitsByFilter,
            addUnit: addUnit,
            updateUnit: updateUnit,
            getUnitGroups: getUnitGroups,
            getUnitGroupsByContractor: getUnitGroupsByContractor,
            addUnitGroup: addUnitGroup,
            updateUnitGroup: updateUnitGroup,
            getUnitBatches: getUnitBatches,
            addUnitBatch: addUnitBatch,
            updateUnitBatch: updateUnitBatch,
            uploadCertificate: uploadCertificate,
            moveUnitBatches: moveUnitBatches,
            deleteCertificateFromUnitBatch: deleteCertificateFromUnitBatch,
            updateUnitActiveStatus: updateUnitActiveStatus,
            getUnitBatchesWithLocationLogSummaries: getUnitBatchesWithLocationLogSummaries,
            setActiveStatusOnUnitBatches: setActiveStatusOnUnitBatches

        };

        function getUnits() {
            var deferred = $q.defer();
            var requestUrl = `${unitsWebApiUrl}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getUnitsByContractorId(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${unitsWebApiUrl}/byContractorId?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getUnitsAsDropdownByContractorId(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${unitsWebApiUrl}/asDropdown?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getUnitsByFilter(page = 1, pageSize = 500, filterCriteria = [], sortCriteria = [], locationNumbers = [], unitBatchNumber=null) {
            const deferred = $q.defer();
            const requestUrl = `${unitsWebApiUrl}/byFilter`
            $http
                .post(
                    requestUrl, 
                    {
                        page,
                        pageSize,
                        filterCriteria,
                        sortCriteria,
                        locationNumbers,
                        unitBatchNumber
                    }
                )
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function addUnit(unit) {
            const deferred = $q.defer();
            const requestUrl = `${unitsWebApiUrl}`;
            $http
                .post(requestUrl, unit)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateUnit(unit) {
            const deferred = $q.defer();
            const requestUrl = `${unitsWebApiUrl}`;
            $http
                .put(requestUrl, unit)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getUnitGroups() {
            var deferred = $q.defer();
            var requestUrl = `${unitGroupsWebApiUrl}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getUnitGroupsByContractor(contractorId) {
            var deferred = $q.defer();
            var requestUrl = `${unitGroupsWebApiUrl}/byContractorId?contractorId=${contractorId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addUnitGroup(unitGroup) {
            const deferred = $q.defer();
            const requestUrl = `${unitGroupsWebApiUrl}`;
            $http
                .post(requestUrl, unitGroup)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateUnitGroup(unitGroup) {
            const deferred = $q.defer();
            const requestUrl = `${unitGroupsWebApiUrl}`;
            $http
                .put(requestUrl, unitGroup)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function getUnitBatches(unitId) {
            var deferred = $q.defer();
            var requestUrl = `${unitBatchWebApiUrl}?unitId=${unitId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function getUnitBatchesWithLocationLogSummaries(unitId) {
            var deferred = $q.defer();
            var requestUrl = `${unitBatchWebApiUrl}/withLocationLogSummaries/?unitId=${unitId}`;
            $http({
                url: requestUrl,
                method: "GET",
            }).then(
                function (response) {
                    deferred.resolve(response.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        }

        function addUnitBatch(unitBatch) {
            const deferred = $q.defer();
            const requestUrl = `${unitBatchWebApiUrl}`;
            $http
                .post(requestUrl, unitBatch)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateUnitBatch(unitBatch) {
            const deferred = $q.defer();
            const requestUrl = `${unitBatchWebApiUrl}`;
            $http
                .put(requestUrl, unitBatch)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function uploadCertificate(unitBatchId, mediaId) {
            const deferred = $q.defer();
            const requestUrl = `${unitBatchWebApiUrl}/uploadCertificate?unitBatchId=${unitBatchId}&mediaId=${mediaId}`;
            $http
                .put(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function moveUnitBatches(unitId, unitesBatches) {
            const deferred = $q.defer();
            const requestUrl = `${unitBatchWebApiUrl}/moveUnitBatches?unitId=${unitId}`;
            $http
                .post(requestUrl, unitesBatches)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function deleteCertificateFromUnitBatch(unitBatchId, mediaId) {
            const deferred = $q.defer();
            const requestUrl = `${unitBatchWebApiUrl}/deleteCertificateFromUnitBatch?unitBatchId=${unitBatchId}&mediaId=${mediaId}`;
            $http
                .delete(requestUrl)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function updateUnitActiveStatus(unitIds, isActive) {
            const deferred = $q.defer();
            const requestUrl = `${unitsWebApiUrl}/updateUnitActiveStatus?isActive=${isActive}`;
            $http
                .put(requestUrl, unitIds)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }

        function setActiveStatusOnUnitBatches(unitId, unitBatchIds, isActive) {
            const deferred = $q.defer();
            const requestUrl = `${unitsWebApiUrl}/setUnitBatchesActiveStatus?unitId=${unitId}&isActive=${isActive}`;
            $http
                .put(requestUrl, unitBatchIds)
                .then(response => deferred.resolve(response.data))
                .catch(error => deferred.reject(error));

            return deferred.promise;
        }
    }
})();
