(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('unitBatchLocationDetailsModalController', unitBatchLocationDetailsModalController);

    unitBatchLocationDetailsModalController.$inject = [
        '$translate',
        '$scope',
        '$windowInstance',
        'unitBatch'
    ];

    function unitBatchLocationDetailsModalController(
        $translate,
        $scope,
        $windowInstance,
        unitBatch
    ) {
        $scope.unitBatch = unitBatch;

        $scope.locationGridOptions = {
            dataSource: {
                data: unitBatch.locationLogSummaries,
                schema: {
                    model: {
                        id: 'id'
                    }
                }
            },
            columns: [
                {
                    field: 'locationName',
                    title: $translate.instant('ADMIN_UNITS_LOCATION'),
                    width: "200px"
                },
                {
                    field: 'lineDescription',
                    title: $translate.instant('ADMIN_UNITS_LINE_DESCRIPTION'),
                    width: "200px"
                },
                {
                    field: 'lineComponentSequenceNumber',
                    title: $translate.instant('ADMIN_UNITS_SEQUENCE_NUMBER'),
                    width: "150px"
                }
            ],
            sortable: true,
            height: 350,
            scrollable: {
                virtual: true,
            }
        };

        $scope.close = function() {
            $windowInstance.close();
        };
    }
})();
