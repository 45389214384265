(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('unitsBatchDetailsModalAdministrationController', unitsBatchDetailsModalAdministrationController);

    unitsBatchDetailsModalAdministrationController.$inject = [
        '$translate',
        '$rootScope',
        '$scope',
        '$windowInstance',
        'currentFormType',
        'formTypes',
        'unitService',
        'locations',
        'unitId',
        'unitBatch',
        'mediaService',
        '$q',
        'confirmDialogService'
    ];

    function unitsBatchDetailsModalAdministrationController(
        $translate,
        $rootScope,
        $scope,
        $windowInstance,
        currentFormType,
        formTypes,
        unitService,
        locations,
        unitId,
        unitBatch,
        mediaService,
        $q,
        confirmDialogService
    ) {
        $scope.unitBatch = {};
        $scope.close = close;
        $scope.validateForm = validateForm;
        $scope.viewCertificate = viewCertificate;
        $scope.deleteCertificate = deleteCertificate;

        $scope.locationOptions = {
            buttonClasses: 'k-button'
        };

        $scope.locationTranslations = {
            checkAll: $translate.instant('G_SELECT_ALL'),
            uncheckAll: $translate.instant('G_UNSELECT_ALL'),
            dynamicButtonTextSuffix: $translate.instant('G_SELECTED')
        };

        $scope.selectedLocations = [];

        $scope.locationSelectorSource = locations.map(function (location) {
            return {
                id: location.locationNumber,
                label: location.name
            };
        });

        $scope.certificate = null;

        $scope.fileUploadOptions = {
            multiple: false,
            localization: {
                select: $translate.instant('G_SELECT')
            }
        };
        initController();

        function initController() {
            if (currentFormType === formTypes.edit && unitBatch) {
                $scope.unitBatch = unitBatch;
                _.forEach($scope.unitBatch.locations, function (location) {
                    $scope.selectedLocations.push(location.locationNumber)
                });
            }
        }

        function validateForm(event) {
            event.preventDefault();

            // Clear any existing validation messages
            $scope.validator.hideMessages();

            // Add custom validation for certificate date
            if ($scope.fileArray && $scope.fileArray.length > 0 && !$scope.unitBatch.certificateIssuedDate) {
                $scope.validator.addError('certificateIssuedDate', $translate.instant('CERTIFICATE_DATE_REQUIRED'));
                return;
            }

            if ($scope.validator.validate()) {
                $rootScope.$broadcast('showBusyIndicator', {
                    id: 'unitDetailsModalIndicator',
                    destination: '.k-widget.k-window',
                    overlay: true
                });

                switch (currentFormType) {
                    case formTypes.add:
                        uploadFile().then(function(mediaId){
                            $scope.unitBatch.unitId = unitId;
                            var selectedLocations = [];
                            _.forEach($scope.selectedLocations, function (selectedLocation) {
                                var location = locations.find(obj => {
                                    return obj.locationNumber === selectedLocation.id;
                                });
                                selectedLocations.push(location);
                            });

                            $scope.unitBatch.locations = selectedLocations;

                            if(mediaId){
                                $scope.unitBatch.mediaId = mediaId;
                            }
                            $scope.unitBatch.isActive = true;
                            unitService.addUnitBatch($scope.unitBatch)
                                .then(unitBatch => close($scope.unitBatch))
                                .catch(() => close());
                        });
                        break;
                    case formTypes.edit:
                        uploadFile().then(function(mediaId){
                            var selectedLocations = [];
                            _.forEach($scope.selectedLocations, function (selectedLocation) {
                                var location = locations.find(obj => {
                                    return obj.locationNumber === selectedLocation.id;
                                });
                                selectedLocations.push(location);
                            });

                            $scope.unitBatch.locations = selectedLocations;

                            if(mediaId){
                                $scope.unitBatch.mediaId = mediaId;
                            }

                            unitService.updateUnitBatch($scope.unitBatch)
                                .then(unitBatch => close($scope.unitBatch))
                                .catch(() => close());
                        });
                        break;
                    default:
                        close();
                        break;
                }
            }
        }

        $scope.uploadFile = function() {
            $('#file-upload').click();
        }

        function uploadFile(){
            const deferred = $q.defer();
            if($scope.fileArray && $scope.fileArray[0] )
            {
                var file = $scope.fileArray[0];
                mediaService.asyncEncodeImageFile(file)
                    .then(function (encodedImage) {
                        mediaService.uploadFileWebApi(file.name, encodedImage)
                            .then(function (newMediaId) {
                                deferred.resolve(newMediaId);
                            });
                    }, function (error){
                        deferred.reject(error);
                    });
            } else {
                deferred.resolve();
            }
            return deferred.promise;
        }

        function close(param) {
            $rootScope.$broadcast('hideBusyIndicator', 'unitDetailsModalIndicator');
            $rootScope.$broadcast('hideBusyIndicator', 'deleteCertificateIndicator');
            $windowInstance.close(param);
        }

        function viewCertificate(mediaId) {
            const mediaUrl = mediaService.createMediaUrl(mediaId)
            window.open(mediaUrl, "_blank");
        }

        function deleteCertificate() {
            confirmDialogService.open(
                { modalWidth: 400, modalHeight: 150 },
                $translate.instant('CONFIRM_DELETE_CERTIFICATE_MESSAGE'),
                 null,
                $translate.instant('G_YES'),
                $translate.instant('G_NO')
            ).then(function(result) {
                if (result) {
                    $rootScope.$broadcast('showBusyIndicator', {
                        id: 'deleteCertificateIndicator',
                        destination: '.k-widget.k-window',
                        overlay: true
                    });
                    return unitService.deleteCertificateFromUnitBatch($scope.unitBatch.id, $scope.unitBatch.mediaId);
                }
                return $q.reject();
            }).then(function(response) {
                $scope.unitBatch.mediaId = null;
                $rootScope.$broadcast('hideAllBusyIndicator');
            }).catch(function(error) {
                $rootScope.$broadcast('hideAllBusyIndicator');
            });
        }

    }
})();
