(function () {
    'use strict';

    angular.module('NaviaqWebApp').controller('LocationReferenceController', LocationReferenceController);

    LocationReferenceController.$inject = [
        '$rootScope',
        '$scope',
        '$state',
        '$translate',
        '$kWindow',
        'kendoUtility',
        'locationReferenceService',
        'formTypes',
        'sanitizeService'

    ];

    function LocationReferenceController(
        $rootScope,
        $scope,
        $state,
        $translate,
        $kWindow,
        kendoUtility,
        locationReferenceService,
        formTypes,
        sanitizeService
        )
    {
        var canChangeState = false;

        var customersLocationReferencesDataSource = new kendo.data.DataSource({
            transport: {
                read: e => locationReferenceGridLoad(e)
            },
            toolbar: ['save', 'cancel'],
            group: defaultGroupFilter,
            schema: {
                model: {
                    id: 'LocationReferenceId',
                    fields: {
                        locationReferenceId:        { type: "string", editable: false},
                        customerId:                 { type: "string", editable: false},
                        contractorId:               { type: "string", editable: false},
                        locationNumber:             { type: "number", editable: false },
                        locationName:               { type: "string", editable: false },
                        locationReferenceContent:   { type: "string", editable: true },
                        customerName:               { type: "string", editable: false },
                    }
                },
            }
        });

        const customersLocationReferencesGridOptions = langId => ({
            dataSource: customersLocationReferencesDataSource,
            height: kendoUtility.calculateRemainingSpace(-40),
            columns: [
                {
                    field: 'locationReferenceId',
                    hidden: true,
                    template: function (dataItem) { return dataItem.locationReferenceId; }
                },
                {
                    field: 'customerId',
                    hidden: true,
                    template: function (dataItem) { return dataItem.customerId; }
                },
                {
                    field: 'contractorId',
                    hidden: true,
                    template: function (dataItem) { return dataItem.contractorId; }
                },
                {
                    field: 'locationNumber',
                    title: $translate.instant('CUSTOMERS_LOCATIONREFERENCE_CUSTOMER_LOCATIONNUMBER', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.locationNumber ? dataItem.locationNumber : '';
                    },
                },
                {
                    field: 'locationName',
                    title: $translate.instant('CUSTOMERS_LOCATIONREFERENCE_LOCATIONNAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.locationName ? dataItem.locationName : '';
                    },
                },
                {
                    field: 'locationReferenceContent',
                    title: $translate.instant('CUSTOMERS_LOCATIONREFERENCE_CONTENT', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.locationReferenceContent ? dataItem.locationReferenceContent : '';
                    },
                },
                {
                    field: 'customerName',
                    title: $translate.instant('CUSTOMERS_LOCATIONREFERENCE_CUSTOMER_NAME', null, null, langId),
                    template: function (dataItem) {
                        if (dataItem.dirty) {
                            toggleChangesButtons(false);
                        }
                        return dataItem.customerName ? dataItem.customerName : '';
                    },
                }
                //,{ command: ["edit"], title: "&nbsp;", width: "250px" }
            ],
            editable: true,
            save: function (e) {
                $scope.$applyAsync(function () {
                   sanitizeService.sanitizingGridContent(e);
               });
           },
            filterable: true,
            resizable: true,
            sortable: true,
            groupable: true,
        });

        const defaultGroupFilter = [];
        $scope.locationReferences = [];
        $scope.customersLocationReferencesGridOptions = customersLocationReferencesGridOptions($translate.use());
        $scope.addNewLocationReference = addNewLocationReference;
        $scope.saveChanges = saveChanges;
        $scope.cancelChanges = cancelChanges;

        $scope.toggleChangesButtons = toggleChangesButtons;
        $scope.$on('langChanged', (event, code) => {
            $scope.customersLocationReferencesGridOptions = customersLocationReferencesGridOptions(code);
            const grid = kendoUtility.createWidget('kendoGrid', $scope.customersLocationReferencesGridOptions);
            kendoUtility.changeLangOfWidget(code, [() => grid('#customers-location-references-grid')]);
            langId = code;

        });

        initController();

        function initController() {

            kendo.culture($translate.use() === "no" ? "no-NO" : "en-US");

            $rootScope.$broadcast('showBusyIndicator', {
                id: 'customersLocationReferencesGrid',
                destination: '#customers-location-references-grid'
            });

            toggleChangesButtons(true);

            locationReferenceService.getLocationReferences()
                .then(result => {
                    $scope.locationReferences = result;
                })
                .catch(error => console.error(error))
                .finally(() => {
                    $scope.customersLocationReferencesGridOptions.dataSource.read();
                    $rootScope.$broadcast('hideBusyIndicator', 'customersLocationReferencesGrid');
                });
        }

        function locationReferenceGridLoad(e)
        {
            _.forEach($scope.locationReferences, lr => lr.locationName = lr.location ? lr.location.name : '');
            _.forEach($scope.locationReferences, lr => lr.customerName = lr.customer ? lr.customer.name : '');
            e.success($scope.locationReferences);
        }

        function saveChanges() {
            _.forEach($scope.customersLocationReferencesGrid._data, d =>
                {
                    if(d.dirty)
                    {
                        var locationReferenceDto = {
                            locationReferenceId: d.locationReferenceId,
                            contractorId: d.contractorId,
                            locationNumber: d.locationNumber,
                            customerId: d.customerId,
                            locationReferenceContent: d.locationReferenceContent,
                        };

                        locationReferenceService.updateLocationReference(locationReferenceDto)
                            .then(function (response) {
                                if (response) {
                                    var index = $scope.locationReferences.findIndex(lr => lr.locationReferenceId === response.locationReferenceId);
                                    if(index >= 0)
                                    {
                                        $scope.locationReferences[index].locationReferenceContent = response.locationReferenceContent;
                                        $scope.customersLocationReferencesGridOptions.dataSource.read();
                                    }

                                    toggleChangesButtons(true);
                                    $rootScope.$broadcast('hideBusyIndicator', 'customersLocationReferencesGrid');
                                } else {
                                    $rootScope.$broadcast('hideBusyIndicator', 'customersLocationReferencesGrid');
                                }
                            }, function (error) {
                                $rootScope.$broadcast('hideBusyIndicator', 'customersLocationReferencesGrid');
                            });
                    }
                })
        }

        function cancelChanges() {
            toggleChangesButtons(true)
            $scope.customersLocationReferencesGridOptions.dataSource.read();
        }

        function toggleChangesButtons(disabled) {
            var saveChangesButton = $('button.k-button.k-grid-save-changes-locationreferences');
            var cancelChangesButton = $('button.k-button.k-grid-cancel-changes-locationreferences');
            if (disabled) {
                saveChangesButton.prop('disabled', true);
                saveChangesButton.addClass('k-state-disabled');
                cancelChangesButton.prop('disabled', true);
                cancelChangesButton.addClass('k-state-disabled');
            } else {
                saveChangesButton.prop('disabled', false);
                saveChangesButton.removeClass('k-state-disabled');
                cancelChangesButton.prop('disabled', false);
                cancelChangesButton.removeClass('k-state-disabled');
            }
        }

        function addNewLocationReference() {
            const window = openLocationReferenceAddModal(formTypes.add);
            window.result.then(result => {
                if (result) {
                    $scope.locationReferences = $scope.locationReferences.concat([result]);
                    $scope.customersLocationReferencesGridOptions.dataSource.read();
                }
            });
        }

        function openLocationReferenceAddModal(formType, locationReference) {
            return $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant('CUSTOMERS_LOCATIONREFERENCE_ADD_NEW'),
                    resizable: true,
                    visible: false,
                    width: 500
                },
                templateUrl: 'app/customers/locationReferences/location-reference-create-modal.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'LocationReferenceCreateModalController',
                resolve: {
                    currentFormType: () => formType,
                    locationReference: () => locationReference,
                }
            });
        }

        $scope.$on('$stateChangeStart', function (event, next) {
            if (!canChangeState && !event.defaultPrevented && $('#customers-location-references-grid').data('kendoGrid').dataSource.hasChanges()) {
                event.preventDefault();
                var langId = localStorage['NG_TRANSLATE_LANG_KEY'];
                var windowInstance = $kWindow.open({
                    options: {
                        modal: true,
                        movable: false,
                        resizable: false,
                        visible: false,
                        width: 300
                    },
                    templateUrl: 'app/shared/popups/confirm-modal.html',
                    windowTemplateUrl: 'app/shared/modal-base.html',
                    controller: 'ConfirmModalController',
                    resolve: {
                        content: function () {
                            return $translate.instant('G_UNSAVED_CHANGES_WARNING', null, null, langId);
                        },
                        primaryBtnText: function () {
                            return null;
                        },
                        secondaryBtnText: function () {
                            return null;
                        }
                    }
                });
                windowInstance.result.then(function (response) {
                    if (response) {
                        canChangeState = response;
                        $state.go(next.name);
                    }
                });
            }
        });
    }
})();
